import "core-js/modules/es.number.constructor.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "im-index"
  }, [_c("a-card", {
    attrs: {
      bordered: false
    }
  }, [_c("a-form", {
    attrs: {
      form: _vm.createForm,
      layout: "inline"
    }
  }, [_c("FormItems", {
    attrs: {
      searchKeys: _vm.searchKeys
    },
    scopedSlots: _vm._u([{
      key: "button",
      fn: function fn() {
        return [_c("a-button", {
          attrs: {
            size: "large",
            type: "primary"
          },
          on: {
            click: _vm.handleSearch
          }
        }, [_vm._v("查询")]), _c("a-button", {
          staticStyle: {
            "margin-left": "20px",
            border: "0",
            background: "#f5f5f5"
          },
          attrs: {
            size: "large"
          },
          on: {
            click: _vm.handleReset
          }
        }, [_vm._v("重置")])];
      },
      proxy: true
    }])
  })], 1)], 1), _c("a-card", {
    staticStyle: {
      "margin-top": "20px"
    },
    attrs: {
      borderd: false
    }
  }, [_c("div", {
    staticClass: "flex"
  }, [_c("a-radio-group", {
    on: {
      change: _vm.TabChange
    },
    model: {
      value: _vm.tabActive,
      callback: function callback($$v) {
        _vm.tabActive = $$v;
      },
      expression: "tabActive"
    }
  }, [_c("a-radio-button", {
    attrs: {
      value: 0
    }
  }, [_vm._v(" 待处理(" + _vm._s(_vm.waitTotal) + ") ")]), _c("a-radio-button", {
    attrs: {
      value: 1
    }
  }, [_vm._v(" 已处理(" + _vm._s(_vm.doneTotal) + ") ")])], 1)], 1), _c("a-table", {
    attrs: {
      pagination: false,
      dataSource: _vm.dataSource,
      columns: _vm.columns,
      rowKey: function rowKey(it, i) {
        return it.id;
      },
      loading: _vm.loading,
      scroll: {
        x: "100%"
      }
    },
    scopedSlots: _vm._u([{
      key: "feedbacknickname",
      fn: function fn(feedbacknickname, row) {
        return [_c("a", {
          attrs: {
            href: "/user/detail?uid=".concat(row.feedbackuid),
            target: "_blank"
          }
        }, [_vm._v(_vm._s(feedbacknickname))])];
      }
    }, {
      key: "feedbacktime",
      fn: function fn(feedbacktime, row) {
        return [_c("span", [_vm._v(_vm._s(_vm.$moment(Number(feedbacktime * 1000)).utcOffset(0).format("YYYY-MM-DD HH:mm:ss")))])];
      }
    }, {
      key: "feedbackstatus",
      fn: function fn(feedbackstatus, row) {
        return [_c("span", [_vm._v(_vm._s(feedbackstatus === 0 ? "未处理" : "已处理"))])];
      }
    }, {
      key: "actions",
      fn: function fn(actions, row) {
        return [_c("div", [_c("a", {
          staticStyle: {
            color: "rgb(102, 102, 102)"
          },
          on: {
            click: function click($event) {
              $event.stopPropagation();
              return _vm.handleButton("view", row);
            }
          }
        }, [_vm._v("查看")]), _c("a", {
          staticStyle: {
            "margin-left": "20px"
          },
          on: {
            click: function click($event) {
              $event.stopPropagation();
              return _vm.handleButton("reply", row);
            }
          }
        }, [_vm._v("回复")])])];
      }
    }])
  }), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.page,
      size: _vm.size,
      onPageSizeChange: _vm.onPageSizeChange,
      onShowSizeChange: _vm.onPageSizeChange
    },
    on: {
      "update:page": function updatePage($event) {
        _vm.page = $event;
      },
      "update:size": function updateSize($event) {
        _vm.size = $event;
      }
    }
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };